.archiveviewer-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;

    nav {
        background-color: black;
        min-height: 2rem;
        display: flex;
        align-items: center;
        margin: 0 1rem;
    }
}

.archiveviewer-link {
    color: white;
    text-decoration: none;
    font-weight: 600;
}

.replaywebpage-wrapper {
    background-color: white;
    width: 100%;
    height: 100%;
}

replay-web-page {
    background-color: white;
}
