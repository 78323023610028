.wiki {
    margin: 2rem 4vw;
    text-align: justify;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4vw;

    p {
        font-size: 1.5em;
        font-weight: 400;
        margin-top: 0;
    }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
    .wiki {
        grid-template-columns: repeat(1, 1fr);
    }
}

.content {
    h2 {
        margin: 2rem 4vw;
        font-size: 1.5em;
        font-weight: 200;
    }
}

.link {
    color: rgb(0, 132, 255);
}

.link:hover {
    color: rgb(98, 179, 255);
    text-underline-offset: 0.25rem;
}

.content-scrollcontainer {
    width:100%;
    overflow-x: scroll;
}

.year-list {
    width: fit-content;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    margin: 0 4vw;
    grid-column-gap: 1rem;

    li {
        list-style-type: none;
    }
 }

.year-list-item {
    min-width: 12rem;
    
    h3 {
        font-size: 1.75em;
        font-weight: 200;
        margin-bottom: 0;
    }
}

.year-list-final-item {
    margin-right: 4vw;

    p {
        margin-top: 3.75rem;
    }
}

.graduate-list, .contributor-list {
    list-style-type: none;
    margin-bottom: 1rem;
    
    li {
        margin-bottom: 0.25rem;
    }
}

sup {
     font-size: 0.75rem;
}

.year-list-item-archivelinkcontainer {
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0.25rem;
    margin-bottom: 0.75rem;

    a {
        height: 1.125rem;
    }
}

.year-list-item-archivelinkspacer {
    height: 1.125rem;
}

.contributor-list {
    margin: 0 4vw;
}

::-webkit-scrollbar {
width: 8px;
height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: rgba(255,255,255,0.25);
border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: rgba(255,255,255,0.5);
}
