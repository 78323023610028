footer {
    margin: 2rem 4vw;
}

.footer-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
