/* Apply a natural box layout model to all elements, but allowing components to change */
html {
	box-sizing: border-box;
}
*, *:before, *:after {
	  box-sizing: inherit;
}
body, ul {
 margin: 0;
 padding: 0;
 background-color: black;
}

html {
    font-family: Arial, Helvetica, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    color: white;  
}
